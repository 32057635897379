<template>
  <div class="box">
    <!-- <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div> -->
    <div class="tabNew" v-if="tabBar && !isApp" :class="navBarFixed ? 'navBarFixed': ''">
      <div class="tabTop">
        <div>
          <ul>
            <img class="hongshi" src="../../../../static/common/wap/hongshi.png" alt="">
            <p>
              <span style="font-weight:600;font-size:13px;">红狮启富</span>
              <span>应用版本：5.0.0</span>
              <span>更新时间：2024年09月02日</span>
            </p>
          </ul>
          <img @click="downLoadApp" class="downLoadApp" src="../../../../static/common/wap/downloadApp.png" alt="">
        </div>
      </div>
      <p class="tabBottom">
        <span>应用权限:查看权限  隐私权限: 查看权限</span>
        <span>APP开发者: 香港红狮集团有限公司  应用名称:红狮启富</span>
      </p>
      <img @click="()=>{tabBar = false;}" class="closer" src="../../../../static/common/wap/closer.png" alt="">
    </div>
    <div class="BannerBox">
      <img class="banner" src="/subject/mayPage/wap/banner.png" alt="">
      <div id="login">
        <div class="interactive">
          <input v-model="form.phone" type="text" placeholder="请输入手机号">
          <div class="codeBox">
            <input v-model="form.code" type="text" placeholder="请输入验证码">
            <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
            <span v-else>重新获取({{verSecond}})</span>
          </div>
          <img class="loginBtn" @click="submit(form)" src="/subject/mayPage/wap/loginBtn.png" alt="">
        </div>
        <div v-if="controlImgCodeDialog" class="codeDialog">
          <img :src="imgcodelocal" alt="">
          <p>
            <span>图形验证码:</span>
            <input type="text" v-model="form.codeNumber">
          </p>
          <div>
            <button @click="()=>{this.controlImgCodeDialog = false}">取消</button>
            <button @click="confirm(form.codeNumber)">确定</button>
          </div>
        </div>
      </div>
    </div>
    <div class="content" :class="tabBar ? 'openTabBar' : ''">
      <div class="part part1">
        <img class="part1TitImg" src="/subject/mayPage/wap/part1_title.png" alt="">
        <img class="partBanner" src="/subject/mayPage/wap/part1Banner.png" alt="">
        <p>
          <img @click="live800" src="/subject/mayPage/live800.png" alt="">
          <img @click="toTop" src="/subject/mayPage/go.png" alt="">
        </p>
      </div>
      <div class="part part2">
        <img class="part1TitImg" src="/subject/mayPage/wap/part2_title.png" alt="">
        <span @click="showDialog = true">活动规则</span>
        <div>
          <img src="/subject/mayPage/wap/part2Banner.png" alt="">
        </div>
        <p>
          <img @click="live800" src="/subject/mayPage/live800.png" alt="">
          <img @click="toTop" src="/subject/mayPage/go.png" alt="">
        </p>
      </div>
      <div class="part part3">
        <img class="part1TitImg" src="/subject/mayPage/wap/part3_title.png" alt="">
        <div class="part3Content">
          <li>
            <img src="/subject/mayPage/wap/part3_1.png" alt="">
            <img src="/subject/mayPage/wap/part3_2.png" alt="">
            <img src="/subject/mayPage/wap/part3_3.png" alt="">
          </li>
          <div>
            <img class="setStep" :src="setStep ? '/subject/mayPage/wap/change1.png' : '/subject/mayPage/wap/change2.png'" alt="">
            <img @click="rightArrow" class="rightArrow" src="/subject/mayPage/rightArrow.png" alt="">
          </div>
        </div>
        <p>
          <img @click="live800" src="/subject/mayPage/live800.png" alt="">
          <img @click="toTop" src="/subject/mayPage/go.png" alt="">
        </p>
      </div>
      <div class="part part4">
        <img class="part1TitImg" src="/subject/mayPage/wap/part4_title.png" alt="">
        <img class="partBanner" src="/subject/mayPage/wap/part4Banner.png" alt="">
        <p>
          <img @click="live800" src="/subject/mayPage/live800.png" alt="">
          <img @click="toTop" src="/subject/mayPage/go.png" alt="">
        </p>
      </div>
      <div class="part part5">
        <img class="partBanner" src="/subject/mayPage/wap/part5Banner.png" alt="">
        <div>
          <p>
            <span>
              <img src="../../../../static/index/androidcode.png">
            </span>
            <label>Android</label>
          </p>
          <p>
            <span>
              <img src="../../../../static/index/iosCode.png">
            </span>
            <label>IOS</label>
          </p>
        </div>
      </div>
    </div>
    <div v-if="showDialog" class="dialog">
      <img class="closer" @click="showDialog = false" src="/subject/mayPage/closer.png" alt="">
      <ul class="dialogContent">
        <h3>活动规则</h3>
        <li>
          <span>活动对象：</span>
          活动期间，在本平台开立真实账户，并首次入金的客户
        </li>
        <li>
          <span>活动时间：</span>
          即日起至2023年05月31日23:59
        </li>
        <li style="color:#ad0000;">
          <span>活动规则：</span><br>         
          活动期间，新用户可享双重福利：<br>
          1.	开户赠送$500现金券一张，盈利即送3美金；<br>
          2.	首次入金，充多少送多少，交易可返现，<span style="color:#ad0000;font-weight: 500;">上不封顶</span>；
        </li>
        <li>
          <span>开户领现金券活动：</span><br>
          1. 活动期间，开立真实账户赠送500美元现金券一张；<br>
          2. 现金券领取后可直接交易，使用有效期从当日00:00起计算，共计3天；<br>
          3. 每张现金券仅可交易1笔，现金券持仓单需在最近一次04:00前平仓，或由系统自动平仓；<br>
          4. 现金券交易后盈利即送3美金，直接发放到MT账户，用户可直接提现；<br>
          5. 盈利有效期为平仓后30天内，逾期扣回；<br>
          6. 现金券仅限伦敦金/伦敦银交易品种；<br>
        </li>
        <li>
          <span>首入金活动规则：</span><br>
          首次充值，充多少送多少美元赠金，奖励上不封顶；<br>
          72小时内总入金≥500美元，可额外获得100美元现金；<br>
          首入金奖励如下：<br>
        </li>
        <li class="imgLiBox">
          <img src="/subject/mayPage/wap/table.png" alt="">
          <!-- <table>
            <tr>
              <th>层级</th>
              <th>首入条件(72h内)</th>
              <th>美元赠金(100%赠送)</th>
              <th>美元现金(限时送)</th>
              <th>美元现金提现条件(30天内)</th>
              <th>总获赠金额</th>
            </tr>
            <tr>
              <th>1</th>
              <th>总入金＜$500</th>
              <th>＜$500</th>
              <th>无</th>
              <th>无</th>
              <th>＜500美元赠金</th>
            </tr>
            <tr>
              <th>2</th>
              <th>总入金≥$500</th>
              <th>≥$500</th>
              <th>100</th>
              <th>≥10手</th>
              <th>≥500美元赠金+100美元现金</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>充多少 送多少</th>
              <th>100</th>
              <th></th>
              <th></th>
            </tr>
          </table> -->
        </li>
        <li>
          <span>美元赠金：</span><br>
          1、活动期间内，首次入金可获得等额的美元赠金（即赠金点）回馈；<br>
          2、 首入金有效期为第一笔入金后的72h内，赠送金额将按照72h后最终数额计算；<br>
          3、用户每交易一手伦敦金/伦敦银，返还相对应的赠金金额；该笔资金可交易可提现，用户可登录App-我的-赠金奖励中查看（未满一手按照实际交易手数占一手的比例计算）<br>
          4、美元赠金按交易手数返还，当月交易越多，每手返还越高！<br>
          <span style="color:#000000">
            更多活动详情，请下载App-我的-赠金奖励中查看，或咨询客服。
          </span>
        </li>
        <li>
          <span>美元现金：</span><br>
          1、美元现金直接入账，可交易，可提现；<br>
          2、用户在30天内完成≥10手交易即可提现，品种限伦敦金/伦敦银；<br>
          3、30天内未完成手数要求，活动到期时，系统仅扣回美元现金金额。<br>
          举例说明：A女士，入金520美元，获得100美元现金，账户实际到账金额为520+100=620美元，第15天，A女士完成10手交易，顺利提取100美元现金。
        </li>
        <li>
          <span>注意事项：</span><br>
          1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br>
          2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br>
          3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br>
          4. 凡推广商及其名下客户不参与本活动；<br>
          5. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
        </li>
      </ul>
    </div>
    <div class="dark" v-if="showDialog" @click="()=>{showDialog = false}"></div>
    <!-- <contactus-vue v-if="!isApp"></contactus-vue> -->
    <div class="bottomBar" v-if="tabBar && !isApp" :class="BarIsBottom ? 'BarIsBottom' : ''">
      <p>
        <img src="../../../../static/common/wap/call.png" alt="">
        <span @click="live800">咨询客服</span>
      </p>
      <p>
        <img src="../../../../static/common/wap/dollars.png" alt="">
        <span @click="goLogin">立领$10000+$500新课礼包</span>
      </p>
    </div>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
import { getGuid } from '../../../../utils/guid';
import { verifyImgCode, quickRegister } from '../../../../api/info';
import { mapState  } from "vuex";
export default {
  name:'mayPageWap',
  components:{
    ContactusVue,
  },
  data(){
    return{
      navBarFixed: false,
      scroll: '',
      screenWidth: null,
      form:{
        phone:'',
        code:'',
        codeNumber:'',
      },
      // 控制显示验证码或读秒
      verControl:true,
      // 定时器倒计时
      refreshData:null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog:false,
      // 图片验证码地址
      imgcodelocal:'',
      slider:true,
      rulesModel: false,
      isApp: false,
      setStep:true,
      showDialog:false,
      tabBar:true,
      BarIsBottom:false,
    }
  },
  created(){
    this.$emit("controlShow", true);
    if(this.$route.query.isApp){
      this.isApp = true;
    }
    window.addEventListener('scroll', this.scrollBottom);
  },
  mounted(){
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom) //页面离开后销毁监听事件
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name: "mayPagePc",
        });
      }
    },
  },
  computed: {
    ...mapState('common', {
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt4DownloadUrl: 'iosMt4DownloadUrl',
    })
  },
  methods:{
    // 返回上级页面
    goback(){
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    // 提交验证码
    confirm(attr){
      if(attr){
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res=>{
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          }else{
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      }else{
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form){
      if(form.phone && form.code){
        let data = {
          IsQuickRregister:true,
          Mobile:form.phone,
          Code:form.code,
          Token:this.guid,
          LoginType:1,
          AuthMode:1,
        };
        quickRegister(data).then(res=>{
          console.log(res);
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            window.location.href = 'https://www.rlcproltd.net/uc/login';
          }else{
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      }else{
        this.$message({
          message: '请输入手机号和验证码！',
          type: 'warning',
        });
      }
    },
    // 点击获取验证码
    verCode(attr){
      if(this.isPhone(attr)){
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal = 'https://sem.rlcmarketltd.net' + '/api/proxy/v4/U104?key=' + this.guid;
      }else{
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 回到顶部
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    // 点击箭头切换图片
    rightArrow(){
      this.setStep = !this.setStep;
    },
    // 跳转客服
    live800(){
      window.open(
        this.$parent.live800UrlUc
      );
    },
    // 点击下载app
    downLoadApp(){
      var u = navigator.userAgent;
      if (/(iPhone|iPad|iPod|iOS)/i.test(u)) { //苹果
        try {
          window.webkit.messageHandlers.gotoRegisterReal.postMessage('1');
        } catch (error) {
          window.open(this.iosMt4DownloadUrl);
        }
      } else { // 安卓
        try {
          window.android.gotoRegisterReal();
        } catch (error) {
          window.open(this.androidDownloadUrl);
        }
      }
    },
    // 登录
    goLogin(){
      window.open('https://www.rlcproltd.net/uc/login');
    },
    // 判断是否滑到底部
    scrollBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        // this.BarIsBottom = true;
      }
   },
  }
}
</script>

<style lang="less" scoped>
.box{
  width: 100%;
  position: relative;
  .tabbar {
    width: 100%;
    background-color: #ffffff;
    img {
      margin: 10px 30px;
      width: 130px;
    }
  }
  .navBarFixed {
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .BannerBox{
    position: relative;
    .banner{
      width: 100%;
      display: block;
    }
    #login{
      width: 90%;
      height: auto;
      background-color: #de4142;
      border-radius: 10px;
      padding: 25px 0;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%,0);
      margin: 0 auto;
      .interactive{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        input:focus{
          outline: none;
        }
        input{
          width: 90%;
          height: 49px;
          background: #fff;
          border: 2px solid #de4142;
          border-radius: 10px;
          font-size: 14px;
          text-indent: 25px;
          list-style: none;
        }
        .codeBox{
          width: 92%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px 0;
          input{
            width: 100%;
          }
          span{
            width: 110px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            border-radius: 10px;
            background: #bb2526;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 8px;
            right: 12px;
            cursor: pointer;
          }
        }
        .loginBtn{
          width: 80%;
          height: 49px;
          cursor: pointer;
        }
      }
      .codeDialog{
        width: 90%;
        height: 180px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #fee6e4;
        border-radius: 15px;
        z-index: 99;
        border: 1px solid #ffdbdb;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img{
          width: 120px;
          margin-bottom: 0;
        }
        p{
          span{
            color: #5f0e15;
            font-size: 15px;
            font-weight: 600;
          }
          input{
            width: 150px;
            height: 30px;
            background-color: #ffffff;
            border: none;
            margin-left: 10px;
            text-indent: 10px;
            color: #5f0e15;
            font-size: 14px;
          }
        }
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          button{
            width: 90px;
            height: 30px;
            border: 1px solid #f64b44;
            background-color: #ffffff;
            color: #f64b44;
            font-size: 15px;
            border-radius: 8px;
            margin: 0 10px;
          }
          button:nth-child(2){
            background-color: #f64b44;
            color: #ffffff;
          }
        }
      }
    }
  }
  .content{
    width: 100%;
    height: 3050px;
    background: url('/subject/mayPage/wap/background.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 20px 0;
    .part{
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .part1TitImg{
        width: 100%;
      }
      .partBanner{
        width: 100%;
      }
      p{
        img{
          width: 145px;
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }
    .part2{
      span{
        width: 95px;
        height: 27px;
        border: 1px solid #ffdfbb;
        color: #ffdfbb;
        font-size: 14px;
        text-align: center;
        line-height: 27px;
        margin-top: 10px;
      }
      div{
        width: 90%;
        height: 568px;
        border-radius: 10px;
        margin: 20px auto;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 95%;
        }
      }
    }
    .part3{
      .part3Content{
        width: 90%;
        height: auto;
        background-color: #fdf8f8;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
        box-sizing: border-box;
        padding: 20px 0;
        li{
          width: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          img{
            width: 100%;
            margin-bottom: 10px;
          }
          img:hover{
            border: 2px solid #e4a262;
            border-radius: 15px;
          }
        }
        div{
          width: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          .setStep{
            width: 95%;
          }
          .rightArrow{
            position: absolute;
            width: 14px;
            right: 2%;
            transform: translate(-50%,0);
          }
        }
      }
    }
    .part4{
      .partBanner{
        width: 90%;
        margin: 20px 0;
      }
    }
    .part5{
      margin-top: 30px;
      .partBanner{
        width: 70%;
      }
      div{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        p{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 10px;
          span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 120px;
            border: 1px solid #ffdfbb;
            border-radius: 10px;
            img{
              width: 90%;
            }
          }
          label{
            font-size: 14px;
            color: #ffebd4;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .dialog{
    position: relative;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 95%;
    height: 400px;
    box-shadow:0px 0px 95px 0px rgba(187, 188, 192, 0.1);
    background-color: #fff;
    border-radius: 10px;
    overflow-y: scroll;
    z-index: 99999999;
    .closer{
      width: 20px;
      height: auto;
      position: absolute;
      top: 8px;
      right: 8px;
    }
    .dialogContent{
      box-sizing: border-box;
      padding: 25px 15px 15px;
      overflow: hidden;
      background: url('/subject/mayPage/rulesBackground.png') no-repeat;
      background-size: 100% 100%;
      h3{
        color: #d88f4f;
        text-align: center;
        margin-top: 0px;
      }
      .imgLiBox{
        overflow-x: auto;
        img{
          width: 500px;
        }
      }
      li{
        font-size: 14px;
        color: #464646;
        line-height: 24px;
        span{
          font-weight: 600;
          color: #d88f4f;
        }
        img{
          width: 100%;
          height: auto;
          margin: 20px 0;
        }
        table{
          width: 100%;
          border: 1px solid #464646;
          border-radius: 10px;
          tr{
            th{
              text-align: center;
              font-weight: 500;
              line-height: 24px;
              border-bottom: 1px solid #464646;
              border-right: 1px solid #464646;
              padding: 5px;
            }
            th:last-child{
              border-right: 1px solid transparent;
            }
          }
          tr:nth-child(1){
            th{
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .dark{
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
  }
  .tabNew{
    width: 100%;
    height: 89px;
    background: url('../../../../static/common/wap/tab.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 0 3px;
    // position: relative;
    .tabTop{
      width: 100%;
      height: 51px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      div{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          .hongshi{
            width: 37.5px;
            margin: 0 10px;
          }
          p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 12px;
          }
        }
        .downLoadApp{
          width: 101.5px;
          margin-top: 10px;
          margin-right: 10px;
        }
      }
    }
    .tabBottom{
      font-size: 12px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      padding-left: 10px;
      margin: 5px 0;
    }
    .closer{
      width: 12px;
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }
  .bottomBar{
    width: 100%;
    box-sizing: border-box;
    padding: 12px 0;
    background-color: #fff0ed;
    position: fixed;
    bottom: 0;
    p{
      width: 85%;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      img{
        width: 19px;
        margin-right: 5px;
      }
      span{
        font-size: 15px;
      }
    }
    p:nth-child(1){
      border: 1px solid #d52226;
      border-radius: 20px;
      margin-bottom: 10px;
      span{
        color: #d52226;
      }
    }
    p:nth-child(2){
      background: url('../../../../static/common/wap/botBtn.png') no-repeat;
      background-size: 100% 100%;
      span{
        color: #fff;
      }
    }
  }
  .BarIsBottom{
    position:relative;
    margin-bottom: 100px;
  }
  .openTabBar{
    height: 3160px;
  }
}
@media screen and (min-width: 385px) and (max-width: 409px) {
  .box .content{
    height: 3150px !important;
  }
  .box{
    .openTabBar{
      height: 3230px !important;
    }
  }
}
@media screen and (min-width : 410px) and (max-width: 450px) {
  .box .content{
    height: 3250px !important;
  }
  .box{
    .openTabBar{
      height: 3350px !important;
    }
  }
}
</style>